import React from "react";

function List({ title, subtitle, imageUrl, style }) {
  return (
    <div className="list-item">
      {imageUrl ? <img src={imageUrl} style={style} /> : null}
      <div className="title" style={style ? { marginTop: 20 } : null}>
        {title}
      </div>
      <div className="subtitle">{subtitle}</div>
    </div>
  );
}

export default List;
