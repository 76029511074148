import { useEffect, useState } from "react";
import List from "./List";
import List2 from "./List2";
import "./style/index.css";
import "./style/style.css";
import Faq from "react-faq-component";
import axios from "axios";
import { ref, push, set } from "firebase/database";
import {
  db,
  auth,
  signInWithPhoneNumber,
  RecaptchaVerifier,
} from "./config/firebase";

const data = {
  rows: [
    {
      title: "Do we need to pay any charges?",
      content: `No, Books are totally free of cost, this is just to promote financial literacy among
      students and to increase awareness of Kalpan books among students.`,
    },
    {
      title: "What is the process of selection?",
      content:
        "From all students who will register we will choose 10 students and we will deliver books to them. Process is totally lottery based.",
    },
    {
      title: "How many days this process will take?",
      content: `Every week we will announce 10 lucky students and we will mail them for more details
      like Address.`,
    },
    {
      title: "Can one student apply more than 1 time?",
      content: "No.",
    },
    {
      title: "How many days it will take to deliver books?",
      content:
        "Approximately 10 days but if it’s in Mumbai or Dubai then it will take 2 days’ time only.",
    },
    {
      title: "What is the role of referral points?",
      content:
        " Higher the referral points: Higher the chance of winning Free Books.",
    },
  ],
};

const styles = {
  bgColor: "transparent",
  titleTextColor: "#222",
  rowTitleColor: "#222",
  rowContentColor: "#222",

  // arrowColor: "red",
};

const config = {
  // animate: true,
  // arrowIcon: "V",
  // tabFocus: true
};

function App() {
  const [modalVisible, setModalVisible] = useState(false);
  const [timeLeft, setTimeLeft] = useState(20);
  const [country, setCountry] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [city, setCity] = useState("");
  const passport = { maxHeight: 250 };
  const [otp, setOTP] = useState();
  const [course, setCourse] = useState("");
  const [email, setEmail] = useState("");
  const [OTPMessage, setOTPMessage] = useState("");
  const [OTPVerified, setOTPVerified] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);

  const sendAdminMail = async () => {
    const response = await axios.post(
      "https://tungsten-whip-echidna.glitch.me/api/v1/sendmail/admin",
      {
        userMail: email,
        city: city,
        user: name,
        courseName: course,
        phone: number,
        country: country,
        isWorking: document.querySelector(
          "input[type='radio'][name=working]:checked"
        ).value,
      }
    );
    return response;
  };
  const submitHandler = async (event) => {
    event.preventDefault();
    setSubmitError("");
    // if (!OTPVerified) {
    //   return setSubmitError("Please verify your number.");
    // }
    const res = await uploadData();
    console.log(res);
    setSubmitLoading(true);
    const response = await axios.post(
      "https://tungsten-whip-echidna.glitch.me/api/v1/sendmail/user",
      {
        userMail: email,
        city: city,
        user: name,
        courseName: course,
      }
    );
    const res2 = sendAdminMail();
    if (response) {
      alert(
        "Thank you for showing interest. You have registered with Book Store."
      );
      console.log(response);
    }
    setSubmitLoading(false);
    setCountry("");
    setCity("");
    setName("");
    setNumber("");
    setEmail("");
  };
  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {},
      },
      auth
    );
  };
  const requestOTP = () => {
    const phone = "+91" + number;
    if (phone.length >= 12) {
      generateRecaptcha();
      let appVerifier = window.recaptchaVerifier;
      signInWithPhoneNumber(auth, phone, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;

          setOTPMessage("OTP sent");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const verifyOTP = () => {
    if (otp.length === 6) {
      let confirmationResult = window.confirmationResult;
      confirmationResult
        .confirm(otp)
        .then((result) => {
          setOTPVerified(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(null);
      setModalVisible(false);
    }

    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);
  const handleChange = (event) => {
    const result = event.target.value.replace(/[^\w\s]/gi, "");

    setName(result);
  };

  const handleEmail = (event) => {
    const result = event.target.value;
    setEmail(result);
  };
  const handleNumberChange = (event) => {
    const result = event.target.value.replace(/[^\w\s]/gi, "");

    setNumber(result);
  };
  const handleCityChange = (event) => {
    const result = event.target.value.replace(/[^a-z]/gi, "");

    setCity(result);
  };
  const handleCountryChange = (event) => {
    const result = event.target.value.replace(/[^a-z]/gi, "");

    setCountry(result);
  };
  const handleCourseChange = (event) => {
    const result = event.target.value;
    setCourse(result);
  };
  const uploadData = async () => {
    const postListRef = ref(db, "Registration");
    const newPostRef = push(postListRef);
    set(newPostRef, {
      userMail: email,
      city: city,
      user: name,
      courseName: course,
      phone: number,
      country: country,
      isWorking: document.querySelector(
        "input[type='radio'][name=working]:checked"
      ).value,
      phoneVerfied: OTPVerified,
    });
  };
  return (
    <div
      className="App modal-open"
      style={modalVisible ? { height: "100vh", overflow: "hidden" } : null}
    >
      <div className="container1 container">
        <header>
          <img src={require("./assets/logo-no-background.png")} />
        </header>
        <div className="full-width">
          <div className="wb-cnt">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close d-flex align-items-center justify-content-center"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true" className="ion-ios-close"></span>
                </button>
              </div>
              <div className="modal-body ">
                <h3 className="text-center mb-3">Register</h3>

                <form className="signup-form" onSubmit={submitHandler}>
                  <div className="wb-flex">
                    <div className="wb-left">
                      <div className="form-group mb-2">
                        <label htmlFor="name">
                          Full Name<span className="red">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Name"
                          required
                          value={name}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group mb-2">
                        <label htmlFor="email">
                          Email Address<span className="red">*</span>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email"
                          value={email}
                          onChange={handleEmail}
                          required
                        />
                      </div>
                      <div className="form-group mb-2">
                        <label htmlFor="password">
                          Phone<span className="red">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Phone"
                          required
                          value={number}
                          onChange={handleNumberChange}
                        />
                        {!OTPVerified ? (
                          <div>
                            {OTPMessage ? (
                              <input
                                className="form-control"
                                style={{ marginTop: 10 }}
                                placeholder="Enter OTP"
                                onChange={(e) => setOTP(e.target.value)}
                              />
                            ) : null}
                            {number.length ? (
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div>
                                  {OTPMessage ? (
                                    <span style={{ color: "#59981A" }}>
                                      OTP sent
                                    </span>
                                  ) : null}
                                </div>

                                <button
                                  type="button"
                                  onClick={() =>
                                    OTPMessage ? verifyOTP() : requestOTP()
                                  }
                                  style={{
                                    border: "none",
                                    padding: "2px 12px",
                                    fontSize: 14,
                                    borderRadius: 4,
                                    marginTop: 10,
                                    color: "#fff",
                                    fontWeight: "400",
                                    background: "#59981A",
                                  }}
                                >
                                  {OTPMessage ? "Verify" : "Send OTP"}
                                </button>
                              </div>
                            ) : null}
                          </div>
                        ) : (
                          <span style={{ color: "#59981A" }}>
                            Phone Number verified
                          </span>
                        )}
                      </div>
                      <div className="form-group mb-2">
                        <label htmlFor="password">
                          Country<span className="red">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Country"
                          required
                          value={country}
                          onChange={handleCountryChange}
                        />
                      </div>
                    </div>
                    <div className="wb-right">
                      <div className="form-group mb-2">
                        <label htmlFor="password">
                          Course<span className="red">*</span>
                        </label>
                        <select
                          className="form-control"
                          onChange={handleCourseChange}
                          required
                        >
                          <option>Select Course</option>
                          <option value="CFA L1">CFA L1</option>
                          <option value="CFA L2">CFA L2</option>
                          <option value="CFA L3">CFA L3</option>
                          <option value="FRM P1">FRM P1</option>
                          <option value="FRM P2">FRM P2</option>
                        </select>
                      </div>
                      <div className="form-group mb-2">
                        <label htmlFor="password">
                          Are you working professional?
                          <span className="red">*</span>
                        </label>
                        <div className="flex" style={{ height: 52 }}>
                          <input
                            type="radio"
                            className="form-control radio-btn"
                            name="working"
                            value="Yes"
                            required
                          />
                          Yes
                          <input
                            type="radio"
                            className="form-control radio-btn"
                            name="working"
                            value="No"
                            style={{ marginLeft: 20 }}
                            required
                          />
                          No
                        </div>
                      </div>
                      <div className="form-group mb-2">
                        <label htmlFor="city">
                          City<span className="red">*</span>
                        </label>
                        <input
                          type="city"
                          className="form-control"
                          placeholder="City"
                          id="city"
                          required
                          value={city}
                          onChange={handleCityChange}
                        />
                      </div>
                      <div className="form-group mb-2">
                        <label htmlFor="password">Refferal</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Referral Code"
                        />
                      </div>
                    </div>
                  </div>
                  {submitError.length ? (
                    <p style={{ color: "red", paddingLeft: 10, marginTop: 10 }}>
                      Please verify your Phone Number.
                    </p>
                  ) : null}
                  <div className="form-group mb-2">
                    <button
                      type="submit"
                      className="form-control btn btn-primary rounded submit px-3 mt20"
                    >
                      {submitLoading ? (
                        <div className="loader-container">
                          <div className="loader"></div>
                        </div>
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container2 container">
        <h1>Why to Register?</h1>
        <div className="list2">
          <List
            title={"Lucky draw every week"}
            subtitle={
              "Once you subscribe you will automatically be the part of luckydraw every week"
            }
            imageUrl={require("./assets/luckydraw.png")}
          />
          <List
            title={"Books will be delivered to your home"}
            subtitle={
              "Books will be delivered to your footstep without any inconvenience for you."
            }
            imageUrl={require("./assets/homedelivery.png")}
          />
          <List
            title={"More than 20,000 Students Registered"}
            subtitle={
              "Registered and a large numbers of students with positive feedbacks"
            }
            imageUrl={require("./assets/students.png")}
          />
          <List
            title={"More than 750 Students Received Books"}
            subtitle={"Books already received by a large amount of students."}
            imageUrl={require("./assets/received.png")}
          />
        </div>
      </div>
      <div className="container3 container">
        <h1>Review from Readers</h1>
        <div className="list2">
          <List
            style={passport}
            title={"Pir Freund ( Dubai ) CFA LEVEL 2"}
            subtitle={
              "Got Free Schweser FRM books from the Book Store, got my books within 2 days and the condition is top notch as well.  strongly recommended.  packaging was done nicely. "
            }
          />
          <List
            style={passport}
            title={"Snehal Sharma ( Jaipur ) FRM PART 1"}
            subtitle={
              "The Schweser books are of the highest quality available for Free. As it would cost you around ₹6000 for printing the same material, economically, it is far more beneficial. Received books in 8 days of winning lucky draw"
            }
          />
          <List
            title={"Rakesh Sharma (UP) FRM Part 1"}
            style={passport}
            subtitle={
              "Really good service received the courier tracking immediately after giving my details and received schweser book within 5 days (i am located in Noida). Packaging was good , very professional service. The quality of the book is good. The book was properly binded and print quality and paper quality is also good. "
            }
          />
          <List
            style={passport}
            title={"Shivani Mishra ( Bihar )"}
            subtitle={
              "Good quality books for Free. The service is fast and quick. Ordered Schweser FRM books and got delivery within 4 days even at a remote place. "
            }
          />
        </div>
      </div>
      <div className="container2 container">
        <h1>About Us</h1>
        <div className="list2">
          <List2
            title={"The CFA institute"}
            subtitle={
              "The CFA Institute is a global, not-for-profit professional organization that provides investment professionals with finance education. The institute aims to promote standards in ethics, education, and professional excellence in the global investment services industry"
            }
            link={"https://www.cfainstitute.org"}
          />
          <List2
            title={"Global Association of Risk Professional"}
            subtitle={
              "Global Association of Risk Professionals is a not-for-profit organization and a membership association for risk managers. Its services include setting standards, training, education, industry networking, and promoting risk management practices. "
            }
            link={"https://www.garp.org/"}
          />
        </div>
      </div>
      <div className="container4 container">
        <h1>Frequently Asked Questions</h1>
        <div className="small-container">
          <Faq data={data} className="title" styles={styles} config={config} />
        </div>
      </div>
      <div id="recaptcha-container"></div>
    </div>
  );
}

export default App;
