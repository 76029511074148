import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyD8458tnOrkirLSRJPSJqjIgedB_eOI-68",
  authDomain: "schweserbook.firebaseapp.com",
  databaseURL: "https://schweserbook-default-rtdb.firebaseio.com",
  projectId: "schweserbook",
  storageBucket: "schweserbook.appspot.com",
  messagingSenderId: "643373548781",
  appId: "1:643373548781:web:67d439f4883c08f12c38e4",
  measurementId: "G-XJZEPPVL6W",
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getDatabase(app);
const storage = getStorage(app);

export { auth, db, storage, signInWithPhoneNumber, RecaptchaVerifier };
