import React from "react";

function List({ title, subtitle, imageUrl, style, link }) {
  return (
    <div className="list-item list-item2">
      {imageUrl ? <img src={imageUrl} style={style} /> : null}
      <div className="title" style={style ? { marginTop: 20 } : null}>
        {title}
      </div>
      <div className="subtitle">{subtitle}</div>
      <a href={link} target="_blank">
        <button className="btn-readmore">Know more</button>
      </a>
    </div>
  );
}

export default List;
